import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import formatAmount from "../../../js-functions/formatAmount";
import { DashboardCard, RowBordered } from "./StyledComponents";
import Chart from "react-apexcharts";
import Col from "react-bootstrap/Col";
import UnpaidRate from "./UnpaidRate";

const Outstanding = ({ data, currency }) => {
  const { t } = useTranslation();

  const [series, setSeries] = useState([
    {
      name: t("Not Late"),
      data: [50],
    },
    {
      name: t("Late"),
      data: [50],
    },
  ]);

  useEffect(() => {
    if (data) {
      setSeries([
        {
          name: t("Not Late"),
          data: [parseInt(data.not_dues)],
        },
        {
          name: t("Late"),
          data: [parseInt(data.dues)],
        },
      ]);
    }
  }, [data, t]);

  const axisColor = "#a1acb8";
  const undueColor = "#696cff";
  const dueColor = "#ff3e1d";
  const options = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
      stacked: true,
      stackType: "100%",
      offsetX: 5,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "40%",
        borderRadius: 4,
        borderRadiusApplication: "around",
        borderRadiusWhenStacked: "all",
        dataLabels: {
          position: "center",
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ["#FFFFFFFF"],
      },
      offsetX: 15,
      formatter: function (value) {
        // return value;
        if (value > 0)
          return formatAmount(
            ((parseInt(data.dues) + parseInt(data.not_dues)) * value) / 100,
            true,
            currency,
          );
        return "";
      },
    },
    grid: {
      show: false,
    },
    colors: [undueColor, dueColor],
    tooltip: {
      enabled: false,
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      markers: {
        height: 8,
        width: 8,
        radius: 12,
        offsetX: -3,
      },
      labels: {
        colors: axisColor,
      },
      itemMargin: {
        horizontal: 10,
      },
    },
  };

  return (
    <DashboardCard>
      <RowBordered className="g-0">
        <Col md={8}>
          <Card.Body>
            <div className="text-muted">{t("OutstandingInvoices.text")}</div>
            {data && (
              <>
                <center>
                  <h3
                    className="mt-4"
                    style={{
                      marginBottom: "-15px",
                    }}
                  >
                    {formatAmount(data.dues, true, currency)}
                  </h3>
                </center>
                <Chart
                  options={options}
                  series={series}
                  type="bar"
                  height={100}
                />
              </>
            )}
          </Card.Body>
        </Col>
        <Col md={4}>
          <Card.Body>
            <UnpaidRate data={data} />
          </Card.Body>
        </Col>
      </RowBordered>
    </DashboardCard>
  );
};
export default Outstanding;
