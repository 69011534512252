// import FormCheck from "react-bootstrap/FormCheck";
import formatAmount from "../../../js-functions/formatAmount";
import { Trans, useTranslation } from "react-i18next";
import {
  KBadgeAlert,
  KBadgeBasic,
  KBadgeLabel,
  KBadgeSuccess,
  KButton,
} from "../../CustomTags/StyledComponents";
import { Download } from "react-bootstrap-icons";
import { useParams } from "react-router-dom";
import { FormCheck } from "react-bootstrap";

const InvoicesListRow = ({
  invoice,
  selectedInvoices,
  selectInvoice,
  getCheckoutUrl,
  stripeEnabled,
}) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_API_URL;

  const txnDate = new Date(invoice.txn_date);
  const formattedTxnDate = txnDate.toLocaleDateString();
  const dueDate = new Date(invoice.due_date);
  const formattedDueDate = dueDate.toLocaleDateString();
  const delayTime = Date.now() - dueDate.getTime();
  const delayInDays = parseInt(delayTime / (1000 * 3600 * 24));

  return (
    <tr>
      <td style={{ textAlign: "center" }}>
        <FormCheck
          checked={
            // true if id is in selectedInvoices
            selectedInvoices.filter((inv) => inv.id === invoice.id).length > 0
          }
          onChange={() =>
            selectInvoice({
              id: invoice.id,
              balance: invoice.balance,
              processing_fees: invoice.processing_fees,
            })
          }
        />
      </td>
      <td title={invoice.ref_number}>{invoice.ref_number}</td>
      <td
        className="text-truncate"
        style={{ textAlign: "center", width: "100px" }}
        title={invoice.order_number}
      >
        {invoice.order_number}
      </td>
      <td
        className="text-truncate"
        style={{ textAlign: "center", width: "100px" }}
        title={formattedTxnDate}
      >
        {formattedTxnDate}
      </td>
      <td
        className="text-truncate"
        style={{ textAlign: "center", width: "100px" }}
        title={formattedDueDate}
      >
        {formattedDueDate}
      </td>
      <td
        className="text-truncate"
        style={{ textAlign: "center", width: "100px" }}
        title={formatAmount(invoice.amount)}
      >
        {formatAmount(invoice.amount)}
      </td>
      <td
        className="text-truncate"
        style={{ textAlign: "center", width: "100px" }}
        title={formatAmount(invoice.balance)}
      >
        {formatAmount(invoice.balance)}
      </td>
      <td
        className="text-truncate"
        style={{ textAlign: "center", width: "100px" }}
        title={formatAmount(invoice.processing_fees)}
      >
        {formatAmount(invoice.processing_fees)}
      </td>
      <td
        style={{
          textAlign: "center",
          color: delayInDays > 0 ? "red" : "green",
          width: "20px",
        }}
        className="text-truncate"
        title={delayInDays}
      >
        {delayInDays > 0 && "+"}
        <Trans i18nKey="day" count={delayInDays}>
          {{ delayInDays }} day
        </Trans>
      </td>
      <td
        className="text-truncate"
        title={invoice.label}
        style={{ textAlign: "center" }}
      >
        {invoice.status_label === "PAID" ? (
          <KBadgeSuccess>{t("Paid")}</KBadgeSuccess>
        ) : invoice.status_label === "UNPAID" ? (
          <KBadgeAlert>{t("Unpaid")}</KBadgeAlert>
        ) : invoice.dispute_status_label ? (
          <KBadgeBasic>{invoice.dispute_status_label}</KBadgeBasic>
        ) : (
          <KBadgeLabel>{invoice.status_label}</KBadgeLabel>
        )}
      </td>
      <td>
        {invoice.files.length > 0 && (
          <a
            href={`${baseUrl}/api/v1/download_portal_invoices?portal_token=${id}&invoice_id=${invoice.id}`}
            download
            target="blank"
          >
            <Download size={20} />
          </a>
        )}
      </td>
      <td>
        {parseFloat(invoice.balance) > 0 &&
          invoice.status_label !== "PAID" &&
          stripeEnabled && (
            <KButton onClick={() => getCheckoutUrl([invoice.id])}>
              {t("Pay")}
            </KButton>
          )}
      </td>
    </tr>
  );
};

export default InvoicesListRow;
